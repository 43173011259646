<template>
<div style="max-width: 800px">

  <div class="card-box-full">
    <div class="card-box-header">
      Recently Updated Reputations
    </div>

    <!-- ----------------------------------------------------------------- -->
    <div v-if="errorMessage">
      <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
    </div>

    <div v-if="isRefreshing">
      <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 10px">
        <span class="sr-only"></span>
      </div>
    </div>

    <div v-else>
      <CTabs variant="tabs" :active-tab="0" style="margin-top: 15px">
        <CTab title="Negative Reputation">
          <div class="tab-box" style="padding: 10px">

            <div v-for="fingerprint in negative">
              <table >
                <tr>
                  <td width="100" align="center" valign="top" >
                    <div style="font-size: 12px; font-weight: 500; margin-top: 10px">ML-Model Confidence</div>
                    <span style="font-size: 20px; font-weight: bold">{{ Number(fingerprint.model_confidence * 100).toFixed(0) }}%</span>
                  </td>
                  <td>
                    <div class="alert alert-danger" style="padding: 5px; cursor: pointer" @click="clickedFingerprint(fingerprint.fingerprint)">
                      <table>
                        <tr>
                          <td>
                            <div style="border-bottom: 1px solid #e35557; padding-bottom: 5px; margin-bottom: 5px">
                              <span class="badge badge-pill badge-danger">{{ fingerprint.votes }} Qualified Feedback Votes</span>
                              <span style="font-size: 12px; ">
                                <span style="margin-left: 5px">updated </span>
                                <timeago :datetime="fingerprint.updated"></timeago>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </table>
                      {{ fingerprint.message }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>

          </div>
        </CTab>
        <CTab title="Positive Reputation">
          <div class="tab-box" style="padding: 10px">

            <div v-for="fingerprint in positive">
              <table >
                <tr>
                  <td width="100" align="center" valign="top" >
                    <div style="font-size: 12px; font-weight: 500; margin-top: 10px">ML-Model Confidence</div>
                    <span style="font-size: 20px; font-weight: bold">{{ Number(fingerprint.model_confidence * 100).toFixed(0) }}%</span>
                  </td>
                  <td>
                    <div class="alert alert-success" style="padding: 5px; cursor: pointer" @click="clickedFingerprint(fingerprint.fingerprint)">
                      <table>
                        <tr>
                          <td>
                            <div style="border-bottom: 1px solid #37b760; padding-bottom: 5px; margin-bottom: 5px">
                              <span class="badge badge-pill badge-success">{{ fingerprint.votes }} Qualified Feedback Votes</span>
                              <span style="font-size: 12px; ">
                                <span style="margin-left: 5px">updated </span>
                                <timeago :datetime="fingerprint.updated"></timeago>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </table>
                      {{ fingerprint.message }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>

          </div>
        </CTab>
      </CTabs>
    </div>

  </div>



</div>
</template>

<script>
export default {
  name: 'Recent-Fingerprints',
  data() {
    return {
      errorMessage: null,
      isRefreshing: false,
      negative: [],
      positive: [],
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedFingerprint(fingerprint) {
      var url = "http://admin.robocalls.ai/#/sms-profile/fingerprint/" + fingerprint;
      window.open(url, '_blank');
    },
    //--------------------------------------------------------------------------
    processHistoricalMessages(response) {
      // Make sure we received messages
      if (!response || !response.messages) {
        this.errorMessage = "No messages were returned";
        return;
      }

      this.negative = [];
      this.positive = [];
      for (var x = 0; x < response.messages.length; x++) {
        var message = response.messages[x];
        if (message.positive_reputation) {
          this.positive.push(message);
        } else {
          this.negative.push(message);
        }
      }
    },
    //--------------------------------------------------------------------------
    refresh() {
      if (this.isRefreshing) {
        return;
      } else {
        this.errorMessage = null;
        this.isRefreshing = true;
        this.negative = [];
        this.positive = [];
      }

      // Fetch the historical messages
      var that = this;
      var method = "GET";
      var url = "https://sms-reputation.platform.robocalls.ai/historical-messages";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the response
            try {
              var result = JSON.parse(body);
              that.processHistoricalMessages(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>
